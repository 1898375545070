<template>
  <main>
    <v-card class="overflow-hidden">
      <v-toolbar flat color="white">
        <v-toolbar-title class="grey--text font-weight-bold">
          DESCRIPCIÓN DEL EVENTO
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-chip outlined :color="eventStateColor(currentState.name)">
          {{ currentState.name }}
        </v-chip>
        <v-btn v-if="mode=='edit'" class="ml-2" rounded color="primary" @click="exportDocument()">
          <!-- icon document -->
          <v-icon>mdi-file-document-edit-outline</v-icon>
          Exportar
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" lg="4" md="6">
            <span class="green--text">Tipo de desarmonía</span>
            <v-autocomplete
              dense
              solo
              placeholder="Seleccione un valor"
              :items="disharmonyTypes"
              :error-messages="validationErrors.event_type_id"
              item-text="name"
              item-value="id"
              v-model="newCase.disharmony_type_id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="6">
            <span class="green--text">Tipo de evento</span>
            <v-autocomplete
              dense
              solo
              placeholder="Seleccione un valor"
              :items="eventTypes"
              :error-messages="validationErrors.event_type_id"
              item-text="name"
              item-value="id"
              v-model="newCase.event_type_id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="6">
            <span class="green--text">Subtipo de evento</span>
            <v-autocomplete
              :error-messages="validationErrors.event_subtype_id"
              dense
              solo
              placeholder="Seleccione un valor"
              :items="eventSubtypes"
              item-text="name"
              item-value="id"
              v-model="newCase.event_subtype_id"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            lg="4"
            md="6"
            v-for="(field, i) in newCase.requiredData"
            :key="i"
          >
            <span class="green--text">{{ field.name }}</span>
            <v-autocomplete
              dense
              solo
              placeholder="Seleccione un valor"
              :items="field.requires_options"
              item-text="text"
              item-value="value"
              v-model="newCase.requiredData[i].value"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4">
            <span class="green--text">Fecha</span>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :error-messages="validationErrors.date"
                  :value="parseDate()"
                  v-on="on"
                  solo
                  dense
                >
                </v-text-field>
              </template>
              <v-date-picker
                :max="new Date().toISOString().substr(0, 10)"
                locale="es-co"
                v-model="newCase.date"
                @input="menu2 = false"
                solo
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4">
            <span class="green--text">Hora</span>
            <v-menu
              ref="menu"
              v-model="timeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="newCase.time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :error-messages="validationErrors.time"
                  v-model="newCase.time"
                  readonly
                  v-on="on"
                  solo
                  dense
                >
                </v-text-field>
              </template>
              <v-time-picker
                v-if="timeMenu"
                v-model="newCase.time"
                full-width
                @click:minute="$refs.menu.save(newCase.time)"
              >
              </v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="8" lg="12">
            <span class="green--text">Descripción del evento</span>
            <v-textarea
              :error-messages="validationErrors.description"
              v-model="newCase.description"
              solo
              name="input-7-4"
              placeholder="Escriba su texto aqui..."
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <h3 class="green--text">Información de contacto</h3>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <span class="green--text">Nombre</span>
            <v-text-field
              v-model="newCase.contact_information.name"
              :error-messages="validationErrors.contact_information.name"
              solo
              dense
              placeholder="Escriba su nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <span class="green--text">Apellido</span>
            <v-text-field
              v-model="newCase.contact_information.lastname"
              :error-messages="validationErrors.contact_information.lastname"
              solo
              dense
              placeholder="Escriba su apellido"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <span class="green--text">Número de contacto</span>
            <v-text-field
              v-model="newCase.contact_information.phone_number"
              :error-messages="
                validationErrors.contact_information.phone_number
              "
              solo
              dense
              placeholder="Escriba su número"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <span class="green--text">Correo electrónico</span>
            <v-text-field
              v-model="newCase.contact_information.email"
              :error-messages="validationErrors.contact_information.email"
              solo
              dense
              placeholder="Escriba su correo"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- CARD UBICACION DEL EVENTO -->
    <v-card class="overflow-hidden">
      <v-toolbar flat color="white">
        <v-toolbar-title class="grey--text font-weight-bold">
          UBICACIÓN DEL EVENTO
        </v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="12" md="6" lg="4">
            <span class="green--text">Departamento</span>
            <v-autocomplete
              :items="departments"
              v-model="newCase.department_id"
              :error-messages="validationErrors.department_id"
              @input="setValues"
              placeholder="Seleccione un valor"
              item-text="descripcion"
              item-value="departamento_id"
              solo
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <span class="green--text">Zona</span>
            <v-autocomplete
              :items="zoneFilter"
              v-model="newCase.zone_id"
              item-text="descripcion"
              solo
              dense
              placeholder="Seleccione un valor"
              item-value="zona_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <span class="green--text">Municipio</span>
            <v-autocomplete
              v-model="newCase.municipality_id"
              :error-messages="validationErrors.municipality_id"
              solo
              dense
              :items="municipalitiesFilter"
              placeholder="Seleccione un valor"
              item-text="descripcion"
              item-value="municipio_id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <span class="green--text">Tipo de territorio</span>
            <v-autocomplete
              v-model="newCase.territory_type_id"
              solo
              dense
              :items="territoryTypes"
              placeholder="Seleccione un valor"
              item-text="nombre"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <span class="green--text">Territorio</span>
            <v-autocomplete
              v-model="newCase.territory_id"
              solo
              dense
              :items="getTerritories(newCase.municipality_id)"
              item-text="descripcion"
              item-value="territorio_id"
              placeholder="Seleccione un valor"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <span class="green--text">Comunidad o vereda</span>
            <v-autocomplete
              :items="sidewalksFilter"
              item-text="descripcion"
              item-value="vereda_id"
              v-model="newCase.sidewalk_id"
              solo
              dense
              placeholder="Seleccione un valor"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- CARD INFORMACION AFECTADOS -->
    <v-card class="overflow-hidden">
      <v-toolbar flat color="white">
        <v-toolbar-title class="grey--text font-weight-bold">
          INFORMACIÓN AFECTADOS
        </v-toolbar-title>
        <affected-form
          @affected="addAffected"
          :isFemale="isFemale"
          :affecteds="newCase.affecteds"
        ></affected-form>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-options': [5,10,15]
          }"
          :headers="headers"
          :items="newCase.affecteds"
          :search="search"
          item-key="id"
          class="mt-2"
        >
          <template v-slot:item.state="{ item }">
            <v-chip outlined color="red">
              {{ item.state }}
            </v-chip>
          </template>
          <template v-slot:item.name="{ item }">
            {{ item.name }} {{ item.second_name }}
          </template>
          <template v-slot:item.lastname="{ item }">
            {{ item.lastname }} {{ item.second_surname }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title @click="removeAffected(item)">
                    <v-icon class="mr-2" small>mdi-delete</v-icon>
                    Eliminar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- CARD EVIDENCIA -->
    <v-card class="overflow-hidden">
      <v-toolbar flat color="white">
        <v-toolbar-title class="font-weight-bold grey--text">
          EVIDENCIA
        </v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <evidences :event-id="eventId" ref="evidencesComponent"> </evidences>
        <evidence-item
          v-for="(evidence, i) in newCase.evidences"
          :key="`evidence_${i}`"
          :evidenceItem="evidence"
        >
        </evidence-item>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2 mb-2">
      <v-btn small class="mx-2" color="primary" rounded @click="save()" v-if="$can('handle_ddhh')">
        <v-icon>mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </div>
  </main>
</template>

<script>
import {
  eventTypesList,
  storeEvent,
  updateEvent,
} from "@/services/humansRights";
import AffectedForm from "./affectedForm.vue";
import sweetalert from "sweetalert2";
import Evidences from "./evidences.vue";
import { required, email, numeric } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import getEventStateColor from "../../../utils/eventStateColors";
import EvidenceItem from "../../../components/commonComponents/EvidenceItem";
import { alphasWithSpaces } from "@/utils/customValidators";
import dayjs from "dayjs";
import ddhhDocument from "@/utils/ddhhDocument";
export default {
  props: {
    isFemale: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "create",
    },
  },
  name: "newCase.vue",
  data() {
    return {
      menu2: false,
      timeMenu: false,
      municipalities: [],
      territories: [],
      departments: [],
      sidewalks: [],
      zone: [],
      search: "",
      headers: [
        {
          text: "NO. IDENTIFICACIÓN",
          align: "start",
          sortable: false,
          value: "documentNumber",
        },
        { text: "NOMBRES", value: "name" },
        { text: "APELLIDOS", value: "lastname" },
        { text: "EDAD", value: "age" },
        { text: "GÉNERO", value: "genre" },
        { text: "PUEBLO INDÍGENA", value: "sidewalk.descripcion" },
        { text: "TERRITORIO", value: "territory.descripcion" },
        { text: "ACCIÓN", value: "action", sortable: false },
      ],
      territoryTypes: [],
      disharmonyTypes: [],
      newCase: {
        id: "",
        disharmony_type_id: "",
        event_type_id: "",
        event_subtype_id: "",
        sidewalk_id: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        time:
          new Date().getHours().toString().padStart(2, "0") +
          ":" +
          new Date().getMinutes().toString().padStart(2, "0"),
        description: "",
        department_id: null,
        zone_id: null,
        municipality_id: "",
        territory_type_id: null,
        territory_id: null,
        is_female: false,
        contact_information: {
          name: "",
          lastname: "",
          phone_number: "",
          email: "",
        },
        affecteds: [],
        evidences: [],
        currentState: {},
        requiredData: [],
      },
      eventId: "",
    };
  },
  validations() {
    return {
      newCase: {
        event_type_id: { required },
        event_subtype_id: { required },
        date: { required },
        time: { required },
        description: { required },
        contact_information: {
          name: { alphasWithSpaces },
          lastname: { alphasWithSpaces },
          phone_number: { numeric },
          email: { email },
        },
        department_id: { required },
        municipality_id: { required },
      },
    };
  },
  methods: {
    setValues(){
      this.newCase.zone_id = null;
      this.newCase.municipality_id = null;
    },
    addAffected(affected) {
      this.newCase.affecteds.push(affected);
    },
    getTerritories(municipality_id){
      const territories = this.$store.state.dataSource.data.Municipio.find(
          (item) => item.municipio_id === municipality_id
      )?.municipio_territoriomunicipio || [];

      return this.$store.state.dataSource.data.Territorio.filter(
          (item) => territories.map((item) => item.territorio_id).includes(item.territorio_id)
      );
    },
    removeAffected(affected) {
      const index = this.newCase.affecteds.indexOf(affected);
      if (index !== -1 && affected["id"] !== undefined)
        this.newCase.affecteds[index]["remove"] = true;
      if (index !== -1 && affected["id"] === undefined)
        this.newCase.affecteds.splice(index, 1);
    },
    parseDate() {
      let date = this.newCase.date;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
    async save() {
      this.$v.newCase.$touch();
      if (this.$v.newCase.$invalid) return;
      try {
        this.showLoader();

        const eventRequestData = {
          date: dayjs(this.newCase.date).format("YYYY-MM-DD"),
          time: this.newCase.time,
          description: this.newCase.description,
          territory_id: this.newCase.territory_id,
          department_id: this.newCase.department_id,
          municipality_id: this.newCase.municipality_id,
          zone_id: this.newCase.zone_id,
          user_id: this.user.id,
          neighborhood: "Barrio",
          event_type_id: this.newCase.event_type_id,
          event_subtype_id: this.newCase.event_subtype_id,
          sidewalk_id: this.newCase.sidewalk_id,
          is_female: this.isFemale,
          contact_information: {
            ...(this.newCase.contact_information.name !== ""
              ? { name: this.newCase.contact_information.name }
              : {}),
            ...(this.newCase.contact_information.lastname !== ""
              ? { lastname: this.newCase.contact_information.lastname }
              : {}),
            ...(this.newCase.contact_information.phone_number !== ""
              ? { phone_number: this.newCase.contact_information.phone_number }
              : {}),
            ...(this.newCase.contact_information.email !== ""
              ? { email: this.newCase.contact_information.email }
              : {}),
          },
          affected_users: this.newCase.affecteds.map((affected) => {
            return {
              ...(this.mode === "edit" && affected["id"] !== undefined
                ? { id: affected.id }
                : {}),
              document_number: affected.documentNumber,
              name: affected.name,
              second_name: affected.second_name,
              lastname: affected.lastname,
              second_surname: affected.second_surname,
              genre: affected.genre,
              territory_id: affected.territory.territorio_id,
              sidewalk_id: affected.sidewalk.pueblo_id,
              age: affected.age,
              ...(this.mode === "edit" && affected["id"] !== undefined
                ? { remove: affected.remove }
                : {}),
            };
          }),

          event_subtype_required_values: this.newCase.requiredData.map((x) => {
            return {
              event_subtype_requires_id: x.id,
              value: x.value,
            };
          }),
        };

        const storeEventResponse =
          this.mode === "edit"
            ? await updateEvent(eventRequestData, this.newCase.id)
            : await storeEvent(eventRequestData);

        if (this.mode === "edit") {
          this.$emit("updatedEvent");
        }

        this.eventId = storeEventResponse.data.event_id;

        await this.$refs.evidencesComponent.upload(this.eventId);

        await sweetalert.fire(
          "Registro guardado correctamente",
          "",
          "success"
        );

        this.hideLoader();

        if (this.isFemale) {
          this.$router.push({
            name: "womenRights.case",
            params: {
              mode: "edit",
              id: this.eventId,
            },
          });
        } else {
          this.$router.push({
            name: "humansRights.editCase",
            params: {
              id: this.eventId,
            },
          });
        }
      } catch (err) {
        this.hideLoader();
        console.error(err);
        sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    setEventData(eventData) {
      this.newCase = eventData;
    },
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
    exportDocument(){
      ddhhDocument(this.caseMaped);
    }
  },
  watch: {
    "newCase.event_subtype_id": {
      deep: true,
      handler: function () {
        this.newCase.requiredData = this.requiredData.map((x) => {
          let value = "";
          if (this.mode === "edit") {
            const subtypeRequiresValues =
              this.newCase.event_subtype_values.find(
                (subtypeValue) =>
                  parseInt(subtypeValue.event_type_requires) === parseInt(x.id)
              );
            value = subtypeRequiresValues ? subtypeRequiresValues.value : "";
          }
          return {
            ...x,
            value: value,
          };
        });
      },
    },
  },
  async created() {
    this.showLoader();
    this.municipalities = this.dataSource.data.Municipio;
    this.departments = this.dataSource.data.Departamento.filter(
      (item) => item.descripcion != " "
    );
    this.territories = this.dataSource.data.Territorio;
    this.zone = this.dataSource.data.Zona;
    this.territoryTypes = this.dataSource.data.tipo_territorio;
    this.sidewalks = this.dataSource.data.Vereda;
    const eventTypesResponse = await eventTypesList();
    this.disharmonyTypes = eventTypesResponse.data?.filter(x => x.is_female === this.isFemale);
    this.hideLoader();
  },
  computed: {
    eventTypes() {
      return (
        this.disharmonyTypes.find(
          (x) => x.id === this.newCase.disharmony_type_id
        )?.event_types || []
      );
    },
    eventSubtypes() {
      return (
        this.eventTypes.find((x) => x.id === this.newCase.event_type_id)
          ?.subtypes || []
      );
    },
    requiredData() {
      return (
        this.eventSubtypes.find((x) => x.id === this.newCase.event_subtype_id)
          ?.required_data || []
      );
    },
    validationErrors() {
      return {
        event_type_id: [
          ...[
            !this.$v.newCase.event_type_id.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        event_subtype_id: [
          ...[
            !this.$v.newCase.event_subtype_id.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        date: [
          ...[
            !this.$v.newCase.date.required ? "Este campo es requerido" : "",
          ].filter((x) => x != ""),
        ],
        time: [
          ...[
            !this.$v.newCase.time.required ? "Este campo es requerido" : "",
          ].filter((x) => x != ""),
        ],
        description: [
          ...[
            !this.$v.newCase.description.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        contact_information: {
          name: [
            ...[
              !this.$v.newCase.contact_information.name.alphasWithSpaces
                ? "Solo se aceptan letras"
                : "",
            ].filter((x) => x != ""),
          ],
          lastname: [
            ...[
              !this.$v.newCase.contact_information.lastname.alphasWithSpaces
                ? "Solo se aceptan letras"
                : "",
            ].filter((x) => x != ""),
          ],
          phone_number: [
            ...[
              !this.$v.newCase.contact_information.phone_number.numeric
                ? "Solo se permiten numeros"
                : "",
            ].filter((x) => x != ""),
          ],
          email: [
            ...[
              !this.$v.newCase.contact_information.email.email
                ? "El formato de correo es incorrecto"
                : "",
            ].filter((x) => x != ""),
          ],
        },
        department_id: [
          ...[
            !this.$v.newCase.department_id.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        municipality_id: [
          ...[
            !this.$v.newCase.municipality_id.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
      };
    },
    zoneFilter() {
      return this.zone.filter(
        (x) => x.departamento === this.newCase.department_id
      );
    },
    municipalitiesFilter() {
      let municipalities =  this.municipalities.filter(
        (x) => x.departamento === this.newCase.department_id
      );
      if (this.newCase.zone_id != null) {
        municipalities = this.municipalities.filter(
          (x) => x.zona === this.newCase.zone_id
        );
      }

      return municipalities;
    },
    sidewalksFilter() {
      return this.sidewalks.filter(
        (x) => x.territorio === this.newCase.territory_id
      );
    },
    currentState() {
      return Object.keys(this.newCase.currentState).length
        ? this.newCase.currentState.state
        : {
            name: "SIN ESTADO ASIGNADO",
          };
    },
    ...mapState(["dataSource", "user"]),
    caseMaped(){
      return {
        id : this.newCase.id,
        disharmonyType : this.disharmonyTypes.find(x => x.id === this.newCase.disharmony_type_id).name,
        eventType : this.eventTypes.find(x => x.id === this.newCase.event_type_id).name,
        eventSubtype : this.eventSubtypes.find(x => x.id === this.newCase.event_subtype_id).name,
        date : this.newCase.date,
        time : this.newCase.time,
        description : this.newCase.description,
        department : this.dataSource.data.Departamento.find(x => x.departamento_id === this.newCase.department_id).descripcion,
        zone : this.dataSource.data.Zona.find(x => x.zona_id === this.newCase.zone_id)?.descripcion || '',
        municipality : this.dataSource.data.Municipio.find(x => x.municipio_id === this.newCase.municipality_id)?.descripcion || '',
        territoryType : this.dataSource.data.TipoTerritorio.find(x => x.id === this.newCase.territory_type_id)?.nombre || '',
        territory : this.dataSource.data.Territorio.find(x => x.territorio_id === this.newCase.territory_id)?.descripcion || '',
        sidewalk : this.dataSource.data.Vereda.find(x => x.vereda_id === this.newCase.sidewalk_id)?.descripcion || '',
        is_female : this.newCase.is_female,
        affectedInfo : this.newCase.affecteds.map(x => {
          return {
            name : `${x.name} ${x.second_name || ''}`,
            lastname : `${x.lastname} ${x.second_surname || ''}`,
            documentNumber : x.documentNumber,
            genre : x.genre,
            age : x.age,
            territory : x.territory.descripcion,
            sidewalk: x.sidewalk.descripcion,
          }
        }),
      }
    }
  },
  components: { AffectedForm, Evidences, EvidenceItem },
};
</script>

<style scoped></style>
