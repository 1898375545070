<template>
  <div>
    <span class="green--text">
      Tipo de archivo permitido: jpg, gif, mp4, pdf, docx, doc, tiff, png. Menores a 5mb
    </span>
    <file-pond
      maxFileSize="5MB"
      allow-multiple="true"
      label-idle="Arrastre aqui sus archivos, o seleccione"
      ref="filePond"
      labelMaxFileSize="El tamaño excede los 5MB"
      labelFileTypeNotAllowed="Extensión no permitida extensiones permitidas jpg, gif, mp4, pdf, docx, doc, tiff, png"
      accepted-file-types="image/jpeg, image/png, image/gif, video/mp4, application/pdf, application/msword, image/tiff"
    />
  </div>
</template>
<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import { storeEventEvidence } from "@/services/humansRights";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
const FilePond = vueFilePond(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

export default {
  props: ["eventId"],
  data() {
    return {
      files: [],
    };
  },
  components: { FilePond },
  methods: {
    async upload(eventId) {
      try {
        const files = this.$refs.filePond.getFiles();
        let formData = new FormData();
        formData.append("event_id", eventId);

        files.forEach((file) => {
          formData.append("files", file.file);
        });

        await storeEventEvidence(formData);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
  },
};
</script>
