<template>
  <v-chip
    label
    class="mx-2 my-2"
    color="green"
    text-color="white"
    @click="downloadEvidence(evidenceItem.id, evidenceItem.description)"
  >
    <v-icon left> mdi-attachment </v-icon>
    {{ evidenceItem.description }}
  </v-chip>
</template>
<script>
import Swal from "sweetalert2";
import { download } from "../../services/evidenceService.js";
export default {
  props: ["evidenceItem"],
  methods: {
    async downloadEvidence(id, name) {
      try {
        this.showLoader();
        await download(id, name);
        this.hideLoader();
      } catch (err) {
        this.hideLoader();
        console.error(err);
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
  },
};
</script>
