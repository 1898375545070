import downloadFile from "../utils/downloadFile";
import baseService from "./baseService";

const resource = "/rest/v1";

export function list(objectId, contentType, module) {
  return baseService.get(`${resource}/human_rights/list-generic-evidence`, {
    params: {
      object_id: objectId,
      content_type: contentType,
      module: module,
    },
  });
}

export function store(formData) {
  return baseService.post(
    `${resource}/human_rights/store-generic-evidence`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export async function download(evidenceId, name) {
  const response = await baseService.get(
    `${resource}/human_rights/event/download-evidence/${evidenceId}`,
    {
      responseType: "blob",
    }
  );

  downloadFile(response.data, "", name);
}
