<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        color="primary"
        outlined
        rounded
        small
        v-bind="attrs"
        v-on="on"
      >
        {{ formButtonLabel }}
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="affected.documentNumber"
                :error-messages="validationErrors.documentNumber"
                dense
                label="Numero de documento"
                required
                hint="Dígite el numero y presione ENTER para buscar datos en los registros de censo"
                solo
                persistent-hint
                v-on:keyup.enter="searchComuner()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="affected.name"
                :error-messages="validationErrors.name"
                dense
                label="Nombre"
                required
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="affected.second_name"
                :error-messages="validationErrors.second_name"
                dense
                label="Segundo Nombre"
                required
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="affected.lastname"
                :error-messages="validationErrors.lastname"
                dense
                label="Apellido"
                solo
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="affected.second_surname"
                :error-messages="validationErrors.second_surname"
                dense
                label="Segundo Apellido"
                solo
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-autocomplete
                v-model="affected.genre"
                :error-messages="validationErrors.genres"
                :items="genres"
                :readonly="isFemale"
                dense
                item-text="description"
                item-value="code"
                label="Genero"
                placeholder="Seleccione un valor"
                solo
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="affected.age"
                :error-messages="validationErrors.age"
                dense
                label="Edad"
                solo
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="affected.territory"
                :error-messages="validationErrors.territory"
                :items="dataSource.data.Territorio"
                dense
                item-text="descripcion"
                item-value="territorio_id"
                label="Territorio"
                placeholder="Seleccione un valor"
                return-object
                solo
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="affected.sidewalk"
                :error-messages="validationErrors.sidewalk"
                :items="
                  dataSource.data.Pueblo.filter(
                    (item) => item.descripcion != ' '
                  )
                "
                dense
                item-text="descripcion"
                label="Pueblo Indígena"
                placeholder="Seleccione un valor"
                return-object
                solo
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
        <v-btn color="blue darken-1" text @click="add"> Agregar</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      absolute
      tile
      color="red accent-2"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import { helpers, numeric, required } from "vuelidate/lib/validators";
import { searchComuner } from "@/services/censusService";
import sweetalert from "sweetalert2";
import { isEmpty } from "lodash";
import { alphasWithSpaces } from "@/utils/customValidators";

const numberTwoDigits = helpers.regex("numberTwoDigits", /^\d{1,2}$/);

export default {
  props: {
    isFemale: {
      type: Boolean,
      default: false,
    },
    affecteds: {
      default: []
    }
  },
  data() {
    return {
      dialog: false,
      snackbar: false,
      text: `Ya existe un afectado registrada para este evento`,
      genres: [
        { code: "F", description: "Femenino" },
        { code: "M", description: "Masculino" },
      ],
      results: [],
      affected: {
        documentNumber: "",
        name: "",
        second_name: "",
        second_surname: "",
        lastname: "",
        genre: "",
        territory: "",
        sidewalk: {},
        age: "",
      },
    };
  },
  validations() {
    return {
      affected: {
        documentNumber: { required, numeric },
        name: { required, alphasWithSpaces },
        second_name: { alphasWithSpaces },
        lastname: { required, alphasWithSpaces },
        second_surname: { alphasWithSpaces },
        genre: { required },
        territory: { required },
        sidewalk: { required },
        age: { required, numeric, numberTwoDigits },
      },
    };
  },
  methods: {
    async searchComuner() {
      this.showLoader();
      const response = await searchComuner(this.affected.documentNumber);
      if (isEmpty(response.data.results)) {
        sweetalert.fire(
          "Info",
          "No se encontraron resultados en el censo",
          "info"
        );
      } else {
        let results = response.data.results[0];

        this.affected = {
          documentNumber: results.numeroidentificacion,
          name: results.nombre1,
          second_name: results.nombre2,
          second_surname: results.apellido2,
          lastname: results.apellido1,
          genre: results.sexo ? "M" : "F",
          territory: this.dataSource.data.Territorio.find(
            (x) => x.territorio_id === results.territorioorigen_actual
          ),
          sidewalk: {},
          age: this._calculateAge(new Date(results.fechanacimiento)),
        };
      }

      this.hideLoader();
    },
    _calculateAge(birthday) {
      // birthday is a date
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    add() {
      if(this.affecteds.filter((item) => item.documentNumber == this.affected.documentNumber).length > 0)
      {
        this.snackbar = true; return;
      }

      this.$v.affected.$touch();
      if (this.$v.affected.$invalid) return;
      this.$emit("affected", { ...this.affected });
      this.affected = {
        name: "",
        second_name: "",
        lastname: "",
        second_surname: "",
        genre: "",
        territory: {},
        age: "",
        sidewalk: {},
        documentNumber: "",
      };
      this.dialog = false;
    },
  },
  created() {
    this.affected.genre = this.isFemale ? "F" : "";
  },
  computed: {
    ...mapState(["dataSource"]),
    validationErrors() {
      return {
        documentNumber: [
          ...[
            !this.$v.affected.documentNumber.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x !== ""),
          ...[
            !this.$v.affected.documentNumber.numeric
              ? "Solo se admiten numeros"
              : "",
          ].filter((x) => x !== ""),
        ],
        name: [
          ...[
            !this.$v.affected.name.required ? "Este campo es requerido" : "",
          ].filter((x) => x !== ""),
          ...[
            !this.$v.affected.name.alphasWithSpaces
              ? "Este campo permite solo letras"
              : "",
          ].filter((x) => x !== ""),
        ],
        second_name: [
          ...[
            !this.$v.affected.second_name.alphasWithSpaces
              ? "Este campo permite solo letras"
              : "",
          ].filter((x) => x !== ""),
        ],
        lastname: [
          ...[
            !this.$v.affected.lastname.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x !== ""),
          ...[
            !this.$v.affected.lastname.alphasWithSpaces
              ? "Este campo permite solo letras"
              : "",
          ].filter((x) => x !== ""),
        ],
        second_surname: [
          ...[
            !this.$v.affected.second_surname.alphasWithSpaces
              ? "Este campo permite solo letras"
              : "",
          ].filter((x) => x !== ""),
        ],
        genre: [
          ...[
            !this.$v.affected.genre.required ? "Este campo es requerido" : "",
          ].filter((x) => x !== ""),
        ],
        territory: [
          ...[
            !this.$v.affected.territory.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x !== ""),
        ],
        sidewalk: [
          ...[
            !this.$v.affected.sidewalk.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x !== ""),
        ],
        age: [
          ...[
            !this.$v.affected.age.required ? "Este campo es requerido" : "",
          ].filter((x) => x !== ""),
          ...[
            !this.$v.affected.age.numeric ? "Solo se admiten numeros" : "",
          ].filter((x) => x !== ""),
          ...[
            !this.$v.affected.age.numberTwoDigits
              ? "Solo se admiten 2 dígitos"
              : "",
          ].filter((x) => x !== ""),
        ],
      };
    },
    formButtonLabel() {
      return this.isFemale ? "Anadir Afectada" : "Anadir Afectado";
    },
    formTitle() {
      return this.isFemale
        ? "Información de la afectada"
        : "Información del afectado";
    },
  },
};
</script>
