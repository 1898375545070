import {jsPDF} from "jspdf";
import {buildDefaultFooter, buildDefaultHeader, centeredText, multicell} from "@/utils/utilsPDF";
import autoTable from "jspdf-autotable";

export default function ddhhDocument(mainCase) {
    const doc = new jsPDF();
    doc.setFont("Helvetica");
    doc.setLineHeightFactor(1.5);
    doc.setFontSize(11);

    buildDefaultHeader(doc);

    doc.setFontSize(12)

    centeredText(mainCase.is_female ? 'OBSERVATORIO VBG MUJERES' : 'OBSERVATORIO DV DD.HH', 45, doc);
    centeredText(`Información general - caso #${mainCase.id}`.toUpperCase(), 55, doc);

    doc.setFontSize(11)

    doc.text(`Tipo de desarmonia : ${mainCase.disharmonyType}`, 20, 70);
    doc.text(`Tipo de evento : ${mainCase.eventType} - Subtipo de evento: ${mainCase.eventSubtype}`, 20, 80);
    doc.text(`Fecha y hora del evento: ${mainCase.date} ${mainCase.time}`, 20, 90);
    doc.text(`Descripción del evento:`, 20, 100);
    // add event description in multiline cell
    multicell(doc, mainCase.description, 20, 107, 180);

    //center title of the document Ubicación del evento
    centeredText("Ubicación del evento".toUpperCase(), 150, doc);
    doc.text(`Departamento: ${mainCase.department}`, 20, 155);
    doc.text(`Municipio: ${mainCase.municipality}`, 20, 160);
    doc.text(`Zona: ${mainCase.zone}`, 20, 165);
    doc.text(`Comunidad o Vereda: ${mainCase.sidewalk}`, 20, 170);
    // tipo de territorio
    doc.text(`Tipo de territorio: ${mainCase.territoryType}`, 20, 175);
    doc.text(`Territorio : ${mainCase.territory}`, 20, 180);

    centeredText("Afectados".toUpperCase(), 190, doc);

    const affecteds = mainCase.affectedInfo.map((affected) => {
        return [
            affected.documentNumber,
            affected.name,
            affected.lastname,
            affected.genre === 'M' ? 'Masculino' : 'Femenino',
            affected.age,
            affected.sidewalk,
            affected.territory
        ]
    })

    let startY = 190;

    autoTable(doc, {
        head: [['Nro. Documento', 'Nombre', 'Apellido', 'Genero', 'Edad','Pueblo Indígena', 'Territorio']],
        body: affecteds,
        startY: startY + 10,
        headStyles :{fillColor : [67, 121, 68]},
        bodyStyles: {fontSize: 10}
    })

    buildDefaultFooter(doc);

    doc.save(mainCase.id+'.pdf')
}