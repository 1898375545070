<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
            <v-icon>mdi-chevron-double-right</v-icon> LISTA DE EVENTOS
            <v-icon>mdi-chevron-double-right</v-icon> Detalle del evento
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
    <create :is-female="isFemale"></create>
  </v-container>
</template>

<script>
import create from "./case/create.vue";

export default {
  name: "newCase.vue",
  props: {
    isFemale: {
      type: Boolean,
      default: false,
    },
  },
  components: { create },
  async created() {
    if (this.$store.state.syncDataSourceFlag) {
      await this.$store.dispatch("syncDataSource");
      this.$store.commit("SET_SYNC_DATA_SOURCE_FLAG", false);
    }
  }
};
</script>

<style scoped></style>
